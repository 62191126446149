<template>

    <!-- password reset modal starts here -->
    <transition name="modal">
        <div class="modalBg passwordResetPopup modal-active" v-if="passwordResetModal.isOpen">
            <div class="modalLayers" @click.prevent="passwordResetModal.isOpen = false"></div>
            <div class="modalBody">

                <div class="addProductTitle">
                    <h3>Change Password</h3>
                </div>

                <div div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%">
                    <ValidationObserver ref="resetPasswordObserver">
                        <form @submit.prevent="validate()">
                            <div id="London" class="tabcontent">
                                <div class="contaient-div horizontal-scroll-fix contaient-div-b">
                                    <div class="editCategoryBlock createCategoryPopup">
                                        <div class="row">
                                            
                                            <!-- current password field starts here -->
                                            <div class="col-md-12" v-if="moduleName == 'profile'">
                                                <ValidationProvider 
                                                    name="current password" 
                                                    :rules="{required:true,min:8,max:250,regex:/^\S.*\S$/}" 
                                                    v-slot="{ errors }"
                                                >
                                                    <div class="tableFromFieldBox">
                                                        <label for="current_password">Current Password<span>*</span></label>
                                                        <div class="tableFromFieldItem passwordBtnIconPd">
                                                            <input 
                                                                :type="passwordResetModal.showCurrentPassowrd ? 'password' : 'text'" 
                                                                name="current_password" 
                                                                id="current_password" 
                                                                placeholder="*******"
                                                                autocomplete="true"
                                                                v-model="passwordResetModal.current_password"
                                                            >
                                                            <button 
                                                                type="button" 
                                                                class="passwordEyeBtn" 
                                                                @click.prevent="passwordResetModal.showCurrentPassowrd = !passwordResetModal.showCurrentPassowrd"
                                                            >
                                                                <svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg" v-if="!passwordResetModal.showCurrentPassowrd">
                                                                    <path d="M325.1 351.5L225.8 273.6c8.303 44.56 47.26 78.37 94.22 78.37C321.8 352 323.4 351.6 325.1 351.5zM320 400c-79.5 0-144-64.52-144-143.1c0-6.789 1.09-13.28 1.1-19.82L81.28 160.4c-17.77 23.75-33.27 50.04-45.81 78.59C33.56 243.4 31.1 251 31.1 256c0 4.977 1.563 12.6 3.469 17.03c54.25 123.4 161.6 206.1 284.5 206.1c45.46 0 88.77-11.49 128.1-32.14l-74.5-58.4C356.1 396.1 338.1 400 320 400zM630.8 469.1l-103.5-81.11c31.37-31.96 57.77-70.75 77.21-114.1c1.906-4.43 3.469-12.07 3.469-17.03c0-4.976-1.562-12.6-3.469-17.03c-54.25-123.4-161.6-206.1-284.5-206.1c-62.69 0-121.2 21.94-170.8 59.62L38.81 5.116C34.41 1.679 29.19 0 24.03 0C16.91 0 9.839 3.158 5.121 9.189c-8.187 10.44-6.37 25.53 4.068 33.7l591.1 463.1c10.5 8.203 25.57 6.333 33.69-4.073C643.1 492.4 641.2 477.3 630.8 469.1zM463.1 256c0 24.85-6.705 47.98-17.95 68.27l-38.55-30.23c5.24-11.68 8.495-24.42 8.495-38.08c0-52.1-43-96-95.1-96c-2.301 .0293-5.575 .4436-8.461 .7658C316.8 170 319.1 180.6 319.1 192c0 10.17-2.561 19.67-6.821 28.16L223.6 149.9c25.46-23.38 59.12-37.93 96.42-37.93C399.5 112 463.1 176.6 463.1 256z"/>
                                                                </svg>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" v-else>
                                                                    <g id="eyeOpen" transform="translate(-355 -428)">
                                                                        <g id="eye-svgrepo-com" transform="translate(355 333.275)">
                                                                            <path id="Path_31" data-name="Path 31" d="M11,98.725c-4.2,0-8.015,2.3-10.828,6.035a.871.871,0,0,0,0,1.04c2.813,3.74,6.625,6.039,10.828,6.039s8.015-2.3,10.828-6.035a.871.871,0,0,0,0-1.04C19.015,101.025,15.2,98.725,11,98.725Zm.3,11.174a4.627,4.627,0,1,1,4.316-4.316A4.63,4.63,0,0,1,11.3,109.9Zm-.14-2.133a2.492,2.492,0,1,1,2.327-2.327A2.488,2.488,0,0,1,11.162,107.766Z"/>
                                                                        </g>
                                                                        <rect id="Rectangle_12990" data-name="Rectangle 12990" width="22" height="22" transform="translate(355 428)" fill="none"/>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <!-- current password field ends here -->

                                            <!-- password field starts here -->
                                            <div class="col-md-12">
                                                <ValidationProvider 
                                                    name="password" 
                                                    vid="password" 
                                                    :rules="{required:true,min:8,max:250,regex:/^\S.*\S$/}" 
                                                    v-slot="{ errors }"
                                                >
                                                    <div class="tableFromFieldBox">
                                                        <label for="password_field">New Password<span>*</span></label>
                                                        <div class="tableFromFieldItem passwordBtnIconPd">
                                                            <a href="" class="genPasswordBtn" @click.prevent="generatePassword(16)">Generate Password</a>
                                                            <input 
                                                                :type="passwordResetModal.showPassword ? 'password' : 'text'" 
                                                                ref="passwordInput" 
                                                                name="password_field" 
                                                                id="password_field" 
                                                                placeholder="*******"
                                                                autocomplete="true"
                                                                v-model="passwordResetModal.password"
                                                            >
                                                            <button 
                                                                type="button" 
                                                                class="passwordEyeBtn" 
                                                                @click.prevent="passwordResetModal.showPassword = !passwordResetModal.showPassword"
                                                            >
                                                                <svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg" v-if="!passwordResetModal.showPassword">
                                                                    <path d="M325.1 351.5L225.8 273.6c8.303 44.56 47.26 78.37 94.22 78.37C321.8 352 323.4 351.6 325.1 351.5zM320 400c-79.5 0-144-64.52-144-143.1c0-6.789 1.09-13.28 1.1-19.82L81.28 160.4c-17.77 23.75-33.27 50.04-45.81 78.59C33.56 243.4 31.1 251 31.1 256c0 4.977 1.563 12.6 3.469 17.03c54.25 123.4 161.6 206.1 284.5 206.1c45.46 0 88.77-11.49 128.1-32.14l-74.5-58.4C356.1 396.1 338.1 400 320 400zM630.8 469.1l-103.5-81.11c31.37-31.96 57.77-70.75 77.21-114.1c1.906-4.43 3.469-12.07 3.469-17.03c0-4.976-1.562-12.6-3.469-17.03c-54.25-123.4-161.6-206.1-284.5-206.1c-62.69 0-121.2 21.94-170.8 59.62L38.81 5.116C34.41 1.679 29.19 0 24.03 0C16.91 0 9.839 3.158 5.121 9.189c-8.187 10.44-6.37 25.53 4.068 33.7l591.1 463.1c10.5 8.203 25.57 6.333 33.69-4.073C643.1 492.4 641.2 477.3 630.8 469.1zM463.1 256c0 24.85-6.705 47.98-17.95 68.27l-38.55-30.23c5.24-11.68 8.495-24.42 8.495-38.08c0-52.1-43-96-95.1-96c-2.301 .0293-5.575 .4436-8.461 .7658C316.8 170 319.1 180.6 319.1 192c0 10.17-2.561 19.67-6.821 28.16L223.6 149.9c25.46-23.38 59.12-37.93 96.42-37.93C399.5 112 463.1 176.6 463.1 256z"/>
                                                                </svg>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" v-else>
                                                                    <g id="eyeOpen" transform="translate(-355 -428)">
                                                                        <g id="eye-svgrepo-com" transform="translate(355 333.275)">
                                                                            <path id="Path_31" data-name="Path 31" d="M11,98.725c-4.2,0-8.015,2.3-10.828,6.035a.871.871,0,0,0,0,1.04c2.813,3.74,6.625,6.039,10.828,6.039s8.015-2.3,10.828-6.035a.871.871,0,0,0,0-1.04C19.015,101.025,15.2,98.725,11,98.725Zm.3,11.174a4.627,4.627,0,1,1,4.316-4.316A4.63,4.63,0,0,1,11.3,109.9Zm-.14-2.133a2.492,2.492,0,1,1,2.327-2.327A2.488,2.488,0,0,1,11.162,107.766Z"/>
                                                                        </g>
                                                                        <rect id="Rectangle_12990" data-name="Rectangle 12990" width="22" height="22" transform="translate(355 428)" fill="none"/>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <!-- password field ends here -->

                                            <!-- confirm password field starts here -->
                                            <div class="col-md-12">
                                                <ValidationProvider 
                                                    name="confirm password" 
                                                    rules="required|max:250|confirmed:password" 
                                                    v-slot="{ errors }"
                                                >
                                                    <div class="tableFromFieldBox">
                                                        <label for="confirm_password_field">Confirm Password<span>*</span></label>
                                                        <div class="tableFromFieldItem passwordBtnIconPd">
                                                            <input 
                                                                :type="passwordResetModal.showConfirmPassword ? 'password' : 'text'" 
                                                                name="confirm_password_field" 
                                                                id="confirm_password_field" 
                                                                placeholder="*******" 
                                                                autocomplete="true"
                                                                v-model="passwordResetModal.confirm_password"
                                                            >
                                                            <button 
                                                                type="button" 
                                                                class="passwordEyeBtn" 
                                                                @click.prevent="passwordResetModal.showConfirmPassword = !passwordResetModal.showConfirmPassword"
                                                            >
                                                                <svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg" v-if="!passwordResetModal.showConfirmPassword">
                                                                    <path d="M325.1 351.5L225.8 273.6c8.303 44.56 47.26 78.37 94.22 78.37C321.8 352 323.4 351.6 325.1 351.5zM320 400c-79.5 0-144-64.52-144-143.1c0-6.789 1.09-13.28 1.1-19.82L81.28 160.4c-17.77 23.75-33.27 50.04-45.81 78.59C33.56 243.4 31.1 251 31.1 256c0 4.977 1.563 12.6 3.469 17.03c54.25 123.4 161.6 206.1 284.5 206.1c45.46 0 88.77-11.49 128.1-32.14l-74.5-58.4C356.1 396.1 338.1 400 320 400zM630.8 469.1l-103.5-81.11c31.37-31.96 57.77-70.75 77.21-114.1c1.906-4.43 3.469-12.07 3.469-17.03c0-4.976-1.562-12.6-3.469-17.03c-54.25-123.4-161.6-206.1-284.5-206.1c-62.69 0-121.2 21.94-170.8 59.62L38.81 5.116C34.41 1.679 29.19 0 24.03 0C16.91 0 9.839 3.158 5.121 9.189c-8.187 10.44-6.37 25.53 4.068 33.7l591.1 463.1c10.5 8.203 25.57 6.333 33.69-4.073C643.1 492.4 641.2 477.3 630.8 469.1zM463.1 256c0 24.85-6.705 47.98-17.95 68.27l-38.55-30.23c5.24-11.68 8.495-24.42 8.495-38.08c0-52.1-43-96-95.1-96c-2.301 .0293-5.575 .4436-8.461 .7658C316.8 170 319.1 180.6 319.1 192c0 10.17-2.561 19.67-6.821 28.16L223.6 149.9c25.46-23.38 59.12-37.93 96.42-37.93C399.5 112 463.1 176.6 463.1 256z"/>
                                                                </svg>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" v-else>
                                                                    <g id="eyeOpen" transform="translate(-355 -428)">
                                                                        <g id="eye-svgrepo-com" transform="translate(355 333.275)">
                                                                            <path id="Path_31" data-name="Path 31" d="M11,98.725c-4.2,0-8.015,2.3-10.828,6.035a.871.871,0,0,0,0,1.04c2.813,3.74,6.625,6.039,10.828,6.039s8.015-2.3,10.828-6.035a.871.871,0,0,0,0-1.04C19.015,101.025,15.2,98.725,11,98.725Zm.3,11.174a4.627,4.627,0,1,1,4.316-4.316A4.63,4.63,0,0,1,11.3,109.9Zm-.14-2.133a2.492,2.492,0,1,1,2.327-2.327A2.488,2.488,0,0,1,11.162,107.766Z"/>
                                                                        </g>
                                                                        <rect id="Rectangle_12990" data-name="Rectangle 12990" width="22" height="22" transform="translate(355 428)" fill="none"/>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <!-- confirm password field ends here -->

                                            <!-- save button field starts here -->
                                            <div class="col-md-12">
                                                <div class="formCustomerBtn">
                                                    <loaderBtn v-if="passwordResetModal.isLoading"/>
                                                    <button v-else type="submit">Update</button>
                                                </div>
                                            </div>
                                            <!-- save button field ends here -->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>

                <div class="modalCloseIcon">
                    <button @click.prevent="passwordResetModal.isOpen = false">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                            <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                        </svg>
                    </button>
                </div>

            </div>
        </div>
    </transition>
    <!-- password reset modal ends here -->

</template>

<script>
import { MessageBox, Message, Loading } from 'element-ui'
import { mapGetters } from 'vuex';
import loaderBtn from "../loaderBtn.vue";

export default {
    name:"PasswordResetModal",
    data(){
        return {

            passwordResetModal: {
                current_password: '',
                password: '',
                confirm_password: '',
                showPassword: true,
                showCurrentPassowrd: true,
                showConfirmPassword: true,

                isOpen: false,
                isLoading: false,

                userId: '',
            },

        }
    },
    props:{
        moduleName:{
            type: String,
            required: true,
            description: 'module name',
        },
    },
    components:{
        loaderBtn
    },
    computed:{
        ...mapGetters({
            settings: "settings_module/settings",
        }),
    },
    watch:{
    },
    methods:{

        openModal(userId){

            let user_id = "";

            if(this.moduleName == 'customer' || 
            this.moduleName == 'user_management'){

                user_id = userId;

            }else if(this.moduleName == 'profile'){

                user_id = "";

            }

            this.passwordResetModal = {
                current_password: '',
                password: '',
                confirm_password: '',
                showPassword: true,
                showCurrentPassowrd: true,
                showConfirmPassword: true,

                isOpen: true,
                isLoading: false,

                userId: user_id,
            }


            this.$nextTick((_) => {

                if(this.$refs.resetPasswordObserver){

                    this.$refs.resetPasswordObserver.reset();

                }

            });

        },

        generatePassword(length){

            let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+|}{?><:~``}";
            let retVal = "";

            for(var i = 0, n = charset.length; i < length; ++i){

                retVal += charset.charAt(Math.floor(Math.random() * n));

            }

            this.$refs.passwordInput.value = retVal;
            this.passwordResetModal.password = retVal;

            this.$refs.passwordInput.focus();

        },

        validate(){

            this.$refs.resetPasswordObserver.validate().then(success => {

                if(!success){

                    const errors = Object.entries(this.$refs.resetPasswordObserver.errors)
                    .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

                    this.$refs.resetPasswordObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                }else{

                    this.handleUpdatePassword();

                }

            });

        },

        async handleUpdatePassword(){

            let apiEndPoint = "";

            let formData = new FormData();

            if(this.moduleName == 'customer'){

                apiEndPoint = "updateCustomerPassword";

                formData.append('id',this.passwordResetModal.userId);
                formData.append('password',this.passwordResetModal.password);
                formData.append('password_confirmation',this.passwordResetModal.confirm_password);

            }else if(this.moduleName == 'user_management'){

                apiEndPoint = "admin/update-password";

                formData.append('id',this.passwordResetModal.userId);
                formData.append('password',this.passwordResetModal.password);
                formData.append('password_confirmation',this.passwordResetModal.confirm_password);

            }else if(this.moduleName == 'profile'){

                apiEndPoint = "updatePassword";

                formData.append('current_password',this.passwordResetModal.current_password);
                formData.append('new_password',this.passwordResetModal.password);

            }

            this.passwordResetModal.isLoading = true;
            try{
                let res = await this.$axios.post(apiEndPoint,formData);
                if(res.data.status_code == '1020' || 
                res.data.status_code == '1191'){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    if(this.moduleName == 'profile'){

                        let token = this.$store.state.user.token;
                    
                        let updatedAdmin = res.data.admin;

                        updatedAdmin.token = token;
                        
                        let user = updatedAdmin;
          
                        user.roles = user.roles.map((role)=>{
                            return role.name;
                        });

                        this.$store.state.user = user;

                        this.$cookies.set("meri_web_shop.current_user", updatedAdmin);
                        this.$store.commit('loggedIn', user);

                    }

                    this.passwordResetModal.isOpen = false;

                }
            }catch(error){

                if(error.response){
                    
                    if(error.response.data.error.includes('cannot be the old') || 
                    error.response.data.error.includes("Current password") || 
                    error.response.data.error.includes("New password")){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.passwordResetModal.isLoading = false;

            }

        },

    },
}
</script>

<style scoped>

    /* Transition Effects */
    .modal-enter-active, 
    .modal-leave-active {
        transition: all 0.3s ease-in-out;
    }

    /* Initial state when entering */
    .modal-enter{
        opacity: 0;
        visibility: hidden;
    }

    /* Final state when leaving */
    .modal-leave-to {
        opacity: 0;
        visibility: hidden;
    }

</style>